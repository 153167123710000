<script>
export default {
  name: 'IconPicker',
  components: {
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    value: null,

    options: {
      type: Array,
      default: null
    },

    context: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    small: {
      type: Boolean,
      default: false
    },

    noIconOptionEnabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasValidation () {
      return Object.keys(this.validation).length
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <div
    class="color-picker"
    :class="{
      'has-value': value,
      'has-floating-label': label,
      'theme-dark': dark,
      'has-validation': hasValidation,
      'has-error': validation.$error,
      'is-small': small,
      'no-icon-option-enabled': noIconOptionEnabled
    }"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <div class="picker-item-list">
      <div
        class="picker-item"
        :class="{'is-checked': value === ''}"
      >
        <label for="no-icon">Sem ícone</label>

        <input
          name="no-icon"
          :style="`background-image: url(${context}icon-close.svg)`"
          class="radio-option"
          type="radio"
          value=""
          @click="updateValue('')"
        >
      </div>

      <div
        v-for="(radioOption, radioIndex) in options"
        :key="radioIndex"
        class="picker-item"
        :class="{'is-checked': context + radioOption === value}"
      >
        <input
          :style="`background-image: url(${context}${radioOption})`"
          class="radio-option"
          :value="value"
          type="radio"
          @click="updateValue(context + radioOption)"
        >
      </div>
    </div>
    <ValidationMessage :validation="validation" />
  </div>
</template>

<style lang="scss" scoped>
.color-picker {
  width: 100%;
  display: flex;
  padding: 8px 0;
  margin-top: 24px;
  flex-direction: column;
  justify-content: flex-start;

  &.has-floating-label .form-label {
    margin-bottom: 8px;
    transform: translateY(0);
    line-height: 1.2em;
  }

  .picker-item-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .picker-item {
      position: relative;
      margin-top: 24px;

      label {
        transition: all 200ms ease;
        position: absolute;
        font-size: 9px;
        bottom: -4px;
        opacity: .3;
      }

      .radio-option {
        opacity: .3;
        cursor: pointer;
        appearance: none;
        margin-right: 16px;
        border-radius: 16px;
        transition: all 200ms ease;
        background-size: 84%;
        height: 72px;
        width: 72px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .picker-item {
    &:not(.is-checked) {
      &:hover label,
      &:hover .radio-option {
        transform: scale(1.05);
        opacity: 1;
      }
    }

    &.is-checked label,
    &.is-checked .radio-option {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  &.is-small {
    .picker-item .radio-option {
      height: 40px;
      width: 40px;
    }
  }

  .form-input-messages-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
</style>
