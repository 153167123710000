var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color-picker",class:{
    'has-value': _vm.value,
    'has-floating-label': _vm.label,
    'theme-dark': _vm.dark,
    'has-validation': _vm.hasValidation,
    'has-error': _vm.validation.$error,
    'is-small': _vm.small,
    'no-icon-option-enabled': _vm.noIconOptionEnabled
  }},[(_vm.label)?_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"picker-item-list"},[_c('div',{staticClass:"picker-item",class:{'is-checked': _vm.value === ''}},[_c('label',{attrs:{"for":"no-icon"}},[_vm._v("Sem ícone")]),_c('input',{staticClass:"radio-option",style:(("background-image: url(" + _vm.context + "icon-close.svg)")),attrs:{"name":"no-icon","type":"radio","value":""},on:{"click":function($event){return _vm.updateValue('')}}})]),_vm._l((_vm.options),function(radioOption,radioIndex){return _c('div',{key:radioIndex,staticClass:"picker-item",class:{'is-checked': _vm.context + radioOption === _vm.value}},[_c('input',{staticClass:"radio-option",style:(("background-image: url(" + _vm.context + radioOption + ")")),attrs:{"type":"radio"},domProps:{"value":_vm.value},on:{"click":function($event){return _vm.updateValue(_vm.context + radioOption)}}})])})],2),_c('ValidationMessage',{attrs:{"validation":_vm.validation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }